
<!--
 * @Description: 订单结算、生成结算数据
 * @Author: 琢磨先生
 * @Date: 2022-07-12 01:08:26
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-08-08 23:59:28
-->

<template>
  <el-button type="success" @click="onOpen">订单结算</el-button>
  <el-dialog title="订单房费结算" v-model="visibleDialog" width="800px" :append-to-body="true" :destroy-on-close="true"
    :close-on-click-modal="false" :close-on-press-escape="false">
    <el-alert type="warning" :closable="false" title="">
      生成订单结算数据后、订单将自动关闭、后续将无法对订单修改！
    </el-alert>
    <el-descriptions title="订单信息" style="margin-top: 20px">
      <el-descriptions-item label="订单类型">{{
          order.type_text
      }}</el-descriptions-item>
      <el-descriptions-item label="来源">{{
          order.ota_text
      }}</el-descriptions-item>

      <el-descriptions-item label="单号">{{
          order.order_no
      }}</el-descriptions-item>
      <el-descriptions-item label="联系人">{{
          order.contact_name
      }}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{
          order.mobile_phone
      }}</el-descriptions-item>

      <el-descriptions-item label="订单金额">
        <span class="text-16-bold blur">￥{{ order.decimal_total_amount }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="已付金额">
        <span class="text-16-bold orange">￥{{ order.decimal_receipt_amount }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="违约金额">
        <span class="text-16-bold red">￥{{ order.decimal_break_amount }}</span>
      </el-descriptions-item>

      <el-descriptions-item label="状态">
        <el-tag :type="
          order.status == 10 ? 'warning' : order.status == 15 ? '' : 'info'
        ">{{ order.status_text }}</el-tag>
      </el-descriptions-item>
    </el-descriptions>

    <el-table class="order_cancel_table" :data="current_order.order_stores" border @selection-change="selectionChange">
      <el-table-column label="房间" width="400">
        <template #default="scope">
          <div>{{ scope.row.house_name }}</div>
          <div>房号:{{ scope.row.house_no }}</div>
        </template>
      </el-table-column>
      <el-table-column label="房费">
        <template #default="scope">
          <div>
            {{ scope.row.checkin_date }} 至 {{ scope.row.checkout_date }}，{{
                scope.row.days
            }}晚
          </div>
          <div>房费：￥{{ scope.row.decimal_amount }}</div>
          <div>违约金：￥{{ scope.row.decimal_break_amount }}</div>
        </template>
      </el-table-column>
    </el-table>


    <div style="color:#303133;font-size: 16px;font-weight: 700;margin-top: 20px;">结果预览</div>
    <el-table :data="model.settle_list" v-if="model.settle_list">
      <el-table-column label="房间" width="300">
        <template #default="scope">
          <div>{{ scope.row.order_store.house_name }}</div>
          <div>房号:{{ scope.row.order_store.house_no }}</div>
        </template>
      </el-table-column>
      <el-table-column label="规则" prop="income_rule_name"></el-table-column>
      <el-table-column label="订单金额">
        <template #default="scope">
          ￥{{ scope.row.decimal_order_amount }}
        </template>
      </el-table-column>
      <el-table-column label="房东收益">
        <template #default="scope">
          ￥{{ scope.row.decimal_amount }}
        </template>
      </el-table-column>
    </el-table>

    <el-form :model="form" ref="form" :rules="rules" style="margin-top: 20px">
      <el-form-item label="" style="margin-top: 20px">
        <el-input v-model="form.remark" type="textarea" rows="4" placeholder="请填写结算备注信息"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span style="margin-right:20px;color:#909399;">生成结算并提交审核</span>
      <el-button type="primary" @click="onSubmit" :loading="saving">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      visibleDialog: false,
      saving: false,
      current_order: {},
      form: {},
      rules: {},
      model: {},
    };
  },
  props: ["order"],
  emits: ["close"],
  watch: {
    order: {
      handler() {
        if (this.order) {
          this.current_order = Object.assign({}, this.order);
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  created() { },
  methods: {
    /**
     * 获取待结算的计算结果
     */
    loadData() {
      this.$http
        .get(`seller/v1/settle/calc?orderId=${this.order.id}`)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
          }
        });
    },

    /**
     * 打开
     */
    onOpen() {
      this.visibleDialog = true;
      this.form.order_id = this.current_order.id;
    },
    /**
     *
     */
    selectionChange(values) {
      this.order_stores = values;
    },

    /**
     * 提交取消预定
     */
    onSubmit() {
      // this.form.order_stores = [];
      // this.order_stores.forEach((item) => {
      //   this.form.order_stores.push({
      //     id: item.id,
      //   });
      // });
      // if (this.form.order_stores.length <= 0) {
      //   this.$message.info("至少选择一条房间数据");
      //   return;
      // }
      this.form.order_id = this.order.id;
      this.saving = true;
      this.$http.post("seller/v1/settle/create", this.form).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.$emit("close");
          this.visibleDialog = false;
        }
        this.saving = false;
      });
    },
  },
};
</script>

<style>
.order_cancel_table.el-table td.el-table__cell,
.order_cancel_table.el-table th.el-table__cell.is-leaf {
  border: none;
}
</style>
<style scoped>
.text-16-bold {
  font-size: 18px;
  font-weight: bold;
}

.text-16-bold.blur {
  color: #053dc8;
}

.text-16-bold.orange {
  color: #fa0;
}

.text-16-bold.red {
  color: #f5222d;
}

.settle_list {
  margin-top: 20px;
  background: var(--el-color-info-light-9);
  border-radius: 4px;
  padding: 20px;
}
</style>